export default {
    "name": "Heart",
    "paper": {
        "height": 10.0,
        "width": 20.0
    },
    "zones": [
        {
            "faces": [
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -0.0,
                        "t": {
                            "x": 20.0,
                            "y": 5.0
                        }
                    },
                    "id": "A0",
                    "pts": [
                        59,
                        93,
                        23,
                        58,
                        57,
                        22,
                        92
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -0.0,
                        "t": {
                            "x": 8.881784197001252e-16,
                            "y": 5.0
                        }
                    },
                    "id": "A1",
                    "pts": [
                        60,
                        94,
                        20,
                        55,
                        56,
                        21,
                        95
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": 15.0,
                            "y": 6.123233995736767e-16
                        }
                    },
                    "id": "B0",
                    "pts": [
                        70,
                        71,
                        69,
                        65
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 5.0,
                            "y": 0.0
                        }
                    },
                    "id": "B1",
                    "pts": [
                        67,
                        68,
                        66,
                        4
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -157.5,
                        "t": {
                            "x": 2.5000000000000004,
                            "y": 11.670446594798246
                        }
                    },
                    "id": "C0",
                    "pts": [
                        35,
                        45,
                        46
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 22.500000000000004,
                        "t": {
                            "x": -1.6704465947982508,
                            "y": 2.499999999999999
                        }
                    },
                    "id": "C1",
                    "pts": [
                        33,
                        41,
                        42
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -157.5,
                        "t": {
                            "x": 17.5,
                            "y": -1.6704465947982472
                        }
                    },
                    "id": "C2",
                    "pts": [
                        139,
                        53,
                        54
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 22.500000000000004,
                        "t": {
                            "x": 21.67044659479825,
                            "y": 7.5
                        }
                    },
                    "id": "C3",
                    "pts": [
                        37,
                        49,
                        50
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -22.500000000000004,
                        "t": {
                            "x": -1.6704465947982503,
                            "y": 7.5
                        }
                    },
                    "id": "D0",
                    "pts": [
                        34,
                        43,
                        44
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 157.50000000000003,
                        "t": {
                            "x": 2.500000000000001,
                            "y": -1.670446594798247
                        }
                    },
                    "id": "D1",
                    "pts": [
                        32,
                        39,
                        40
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -22.500000000000004,
                        "t": {
                            "x": 21.670446594798246,
                            "y": 2.500000000000001
                        }
                    },
                    "id": "D2",
                    "pts": [
                        38,
                        51,
                        52
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 157.50000000000003,
                        "t": {
                            "x": 17.5,
                            "y": 11.67044659479825
                        }
                    },
                    "id": "D3",
                    "pts": [
                        36,
                        47,
                        48
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": 15.0,
                            "y": 10.0
                        }
                    },
                    "id": "E0",
                    "pts": [
                        8,
                        46,
                        84
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": 20.0,
                            "y": 5.0
                        }
                    },
                    "id": "E1",
                    "pts": [
                        18,
                        42,
                        86
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": 5.0,
                            "y": 0.0
                        }
                    },
                    "id": "E2",
                    "pts": [
                        10,
                        88,
                        54
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": 0.0,
                            "y": 5.0
                        }
                    },
                    "id": "E3",
                    "pts": [
                        19,
                        90,
                        50
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 135.00000000000003,
                        "t": {
                            "x": 20.000000000000004,
                            "y": 10.0
                        }
                    },
                    "id": "F0",
                    "pts": [
                        45,
                        76,
                        2
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 135.00000000000003,
                        "t": {
                            "x": 20.000000000000004,
                            "y": 0.0
                        }
                    },
                    "id": "F1",
                    "pts": [
                        41,
                        78,
                        1
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 135.00000000000003,
                        "t": {
                            "x": 0.0,
                            "y": 0.0
                        }
                    },
                    "id": "F2",
                    "pts": [
                        0,
                        53,
                        80
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 135.00000000000003,
                        "t": {
                            "x": 0.0,
                            "y": 10.000000000000002
                        }
                    },
                    "id": "F3",
                    "pts": [
                        49,
                        82,
                        3
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -135.0,
                        "t": {
                            "x": 20.0,
                            "y": 10.000000000000002
                        }
                    },
                    "id": "G0",
                    "pts": [
                        77,
                        44,
                        2
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -135.0,
                        "t": {
                            "x": 20.0,
                            "y": 0.0
                        }
                    },
                    "id": "G1",
                    "pts": [
                        79,
                        40,
                        1
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -135.0,
                        "t": {
                            "x": 0.0,
                            "y": 0.0
                        }
                    },
                    "id": "G2",
                    "pts": [
                        81,
                        52,
                        0
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -135.0,
                        "t": {
                            "x": 0.0,
                            "y": 10.0
                        }
                    },
                    "id": "G3",
                    "pts": [
                        83,
                        48,
                        3
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 20.0,
                            "y": 5.0
                        }
                    },
                    "id": "H0",
                    "pts": [
                        85,
                        18,
                        43
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 15.0,
                            "y": 0.0
                        }
                    },
                    "id": "H1",
                    "pts": [
                        87,
                        9,
                        39
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 0.0,
                            "y": 5.0
                        }
                    },
                    "id": "H2",
                    "pts": [
                        89,
                        19,
                        51
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 5.0,
                            "y": 10.0
                        }
                    },
                    "id": "H3",
                    "pts": [
                        91,
                        11,
                        47
                    ],
                    "target_side": "recto"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": -1.68234189723614e-16,
                            "y": 10.0
                        }
                    },
                    "id": "Q0",
                    "pts": [
                        21,
                        35,
                        73,
                        34
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 4.4408920985006257e-16,
                            "y": -4.440892098500626e-16
                        }
                    },
                    "id": "Q1",
                    "pts": [
                        20,
                        32,
                        72,
                        33
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": 20.0,
                            "y": 3.36468379447228e-16
                        }
                    },
                    "id": "Q2",
                    "pts": [
                        23,
                        38,
                        75,
                        139
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 20.0,
                            "y": 9.999999999999998
                        }
                    },
                    "id": "Q3",
                    "pts": [
                        22,
                        36,
                        74,
                        37
                    ],
                    "target_side": "verso"
                }
            ],
            "name": "Front",
            "plot_segs": [
                {
                    "p0": 0,
                    "p1": 52
                },
                {
                    "p0": 0,
                    "p1": 53
                },
                {
                    "p0": 0,
                    "p1": 80
                },
                {
                    "p0": 0,
                    "p1": 81
                },
                {
                    "p0": 1,
                    "p1": 40
                },
                {
                    "p0": 1,
                    "p1": 41
                },
                {
                    "p0": 1,
                    "p1": 78
                },
                {
                    "p0": 1,
                    "p1": 79
                },
                {
                    "p0": 2,
                    "p1": 44
                },
                {
                    "p0": 2,
                    "p1": 45
                },
                {
                    "p0": 2,
                    "p1": 76
                },
                {
                    "p0": 2,
                    "p1": 77
                },
                {
                    "p0": 3,
                    "p1": 48
                },
                {
                    "p0": 3,
                    "p1": 49
                },
                {
                    "p0": 3,
                    "p1": 82
                },
                {
                    "p0": 3,
                    "p1": 83
                },
                {
                    "p0": 4,
                    "p1": 66
                },
                {
                    "p0": 4,
                    "p1": 67
                },
                {
                    "p0": 8,
                    "p1": 46
                },
                {
                    "p0": 8,
                    "p1": 84
                },
                {
                    "p0": 9,
                    "p1": 39
                },
                {
                    "p0": 9,
                    "p1": 87
                },
                {
                    "p0": 10,
                    "p1": 54
                },
                {
                    "p0": 10,
                    "p1": 88
                },
                {
                    "p0": 11,
                    "p1": 47
                },
                {
                    "p0": 11,
                    "p1": 91
                },
                {
                    "p0": 18,
                    "p1": 42
                },
                {
                    "p0": 18,
                    "p1": 43
                },
                {
                    "p0": 18,
                    "p1": 85
                },
                {
                    "p0": 18,
                    "p1": 86
                },
                {
                    "p0": 19,
                    "p1": 50
                },
                {
                    "p0": 19,
                    "p1": 51
                },
                {
                    "p0": 19,
                    "p1": 89
                },
                {
                    "p0": 19,
                    "p1": 90
                },
                {
                    "p0": 20,
                    "p1": 32
                },
                {
                    "p0": 20,
                    "p1": 33
                },
                {
                    "p0": 20,
                    "p1": 55
                },
                {
                    "p0": 20,
                    "p1": 94
                },
                {
                    "p0": 21,
                    "p1": 34
                },
                {
                    "p0": 21,
                    "p1": 35
                },
                {
                    "p0": 21,
                    "p1": 56
                },
                {
                    "p0": 21,
                    "p1": 95
                },
                {
                    "p0": 22,
                    "p1": 36
                },
                {
                    "p0": 22,
                    "p1": 37
                },
                {
                    "p0": 22,
                    "p1": 57
                },
                {
                    "p0": 22,
                    "p1": 92
                },
                {
                    "p0": 23,
                    "p1": 38
                },
                {
                    "p0": 23,
                    "p1": 58
                },
                {
                    "p0": 23,
                    "p1": 93
                },
                {
                    "p0": 23,
                    "p1": 139
                },
                {
                    "p0": 32,
                    "p1": 39
                },
                {
                    "p0": 32,
                    "p1": 40
                },
                {
                    "p0": 32,
                    "p1": 72
                },
                {
                    "p0": 33,
                    "p1": 41
                },
                {
                    "p0": 33,
                    "p1": 42
                },
                {
                    "p0": 33,
                    "p1": 72
                },
                {
                    "p0": 34,
                    "p1": 43
                },
                {
                    "p0": 34,
                    "p1": 44
                },
                {
                    "p0": 34,
                    "p1": 73
                },
                {
                    "p0": 35,
                    "p1": 45
                },
                {
                    "p0": 35,
                    "p1": 46
                },
                {
                    "p0": 35,
                    "p1": 73
                },
                {
                    "p0": 36,
                    "p1": 47
                },
                {
                    "p0": 36,
                    "p1": 48
                },
                {
                    "p0": 36,
                    "p1": 74
                },
                {
                    "p0": 37,
                    "p1": 49
                },
                {
                    "p0": 37,
                    "p1": 50
                },
                {
                    "p0": 37,
                    "p1": 74
                },
                {
                    "p0": 38,
                    "p1": 51
                },
                {
                    "p0": 38,
                    "p1": 52
                },
                {
                    "p0": 38,
                    "p1": 75
                },
                {
                    "p0": 39,
                    "p1": 40
                },
                {
                    "p0": 39,
                    "p1": 87
                },
                {
                    "p0": 40,
                    "p1": 79
                },
                {
                    "p0": 41,
                    "p1": 42
                },
                {
                    "p0": 41,
                    "p1": 78
                },
                {
                    "p0": 42,
                    "p1": 86
                },
                {
                    "p0": 43,
                    "p1": 44
                },
                {
                    "p0": 43,
                    "p1": 85
                },
                {
                    "p0": 44,
                    "p1": 77
                },
                {
                    "p0": 45,
                    "p1": 46
                },
                {
                    "p0": 45,
                    "p1": 76
                },
                {
                    "p0": 46,
                    "p1": 84
                },
                {
                    "p0": 47,
                    "p1": 48
                },
                {
                    "p0": 47,
                    "p1": 91
                },
                {
                    "p0": 48,
                    "p1": 83
                },
                {
                    "p0": 49,
                    "p1": 50
                },
                {
                    "p0": 49,
                    "p1": 82
                },
                {
                    "p0": 50,
                    "p1": 90
                },
                {
                    "p0": 51,
                    "p1": 52
                },
                {
                    "p0": 51,
                    "p1": 89
                },
                {
                    "p0": 52,
                    "p1": 81
                },
                {
                    "p0": 53,
                    "p1": 54
                },
                {
                    "p0": 53,
                    "p1": 80
                },
                {
                    "p0": 53,
                    "p1": 139
                },
                {
                    "p0": 54,
                    "p1": 88
                },
                {
                    "p0": 54,
                    "p1": 139
                },
                {
                    "p0": 55,
                    "p1": 56
                },
                {
                    "p0": 57,
                    "p1": 58
                },
                {
                    "p0": 59,
                    "p1": 92
                },
                {
                    "p0": 59,
                    "p1": 93
                },
                {
                    "p0": 60,
                    "p1": 94
                },
                {
                    "p0": 60,
                    "p1": 95
                },
                {
                    "p0": 65,
                    "p1": 69
                },
                {
                    "p0": 65,
                    "p1": 70
                },
                {
                    "p0": 66,
                    "p1": 68
                },
                {
                    "p0": 67,
                    "p1": 68
                },
                {
                    "p0": 69,
                    "p1": 71
                },
                {
                    "p0": 70,
                    "p1": 71
                },
                {
                    "p0": 75,
                    "p1": 139
                }
            ],
            "points": {
                "0": {
                    "x": 0.0,
                    "y": 0.0
                },
                "1": {
                    "x": 0.0,
                    "y": 0.0
                },
                "10": {
                    "x": 0.0,
                    "y": 0.0
                },
                "11": {
                    "x": 0.0,
                    "y": 0.0
                },
                "139": {
                    "x": -1.0355339059327375,
                    "y": -2.5
                },
                "18": {
                    "x": 0.0,
                    "y": 0.0
                },
                "19": {
                    "x": 0.0,
                    "y": 0.0
                },
                "2": {
                    "x": 0.0,
                    "y": 0.0
                },
                "20": {
                    "x": 2.500000000000001,
                    "y": -2.5
                },
                "21": {
                    "x": 2.500000000000001,
                    "y": 2.5
                },
                "22": {
                    "x": -2.4999999999999982,
                    "y": 2.5000000000000004
                },
                "23": {
                    "x": -2.4999999999999996,
                    "y": -2.5
                },
                "3": {
                    "x": 0.0,
                    "y": 0.0
                },
                "32": {
                    "x": 1.0355339059327389,
                    "y": -2.5
                },
                "33": {
                    "x": 2.5,
                    "y": -1.0355339059327342
                },
                "34": {
                    "x": 2.500000000000001,
                    "y": 1.0355339059327342
                },
                "35": {
                    "x": 1.0355339059327395,
                    "y": 2.5
                },
                "36": {
                    "x": -1.035533905932736,
                    "y": 2.5
                },
                "37": {
                    "x": -2.4999999999999982,
                    "y": 1.035533905932738
                },
                "38": {
                    "x": -2.4999999999999996,
                    "y": -1.0355339059327375
                },
                "39": {
                    "x": 1.1071286912838291e-16,
                    "y": -1.808078365214616
                },
                "4": {
                    "x": 3.0616169978683836e-16,
                    "y": -5.0
                },
                "40": {
                    "x": 1.2785044729599395,
                    "y": -1.2785044729599395
                },
                "41": {
                    "x": 1.2785044729599395,
                    "y": -1.2785044729599395
                },
                "42": {
                    "x": 1.8080783652146137,
                    "y": 0.0
                },
                "43": {
                    "x": 1.8080783652146133,
                    "y": 0.0
                },
                "44": {
                    "x": 1.2785044729599413,
                    "y": 1.2785044729599404
                },
                "45": {
                    "x": 1.2785044729599413,
                    "y": 1.2785044729599377
                },
                "46": {
                    "x": 0.0,
                    "y": 1.8080783652146124
                },
                "47": {
                    "x": 0.0,
                    "y": 1.808078365214612
                },
                "48": {
                    "x": -1.2785044729599377,
                    "y": 1.2785044729599386
                },
                "49": {
                    "x": -1.2785044729599395,
                    "y": 1.2785044729599404
                },
                "50": {
                    "x": -1.8080783652146115,
                    "y": 1.1071286912838266e-16
                },
                "51": {
                    "x": -1.8080783652146115,
                    "y": -1.1071286912838266e-16
                },
                "52": {
                    "x": -1.2785044729599393,
                    "y": -1.278504472959939
                },
                "53": {
                    "x": -1.2785044729599395,
                    "y": -1.278504472959939
                },
                "54": {
                    "x": -1.1071286912838266e-16,
                    "y": -1.8080783652146115
                },
                "55": {
                    "x": 3.75,
                    "y": -1.25
                },
                "56": {
                    "x": 3.75,
                    "y": 1.25
                },
                "57": {
                    "x": -3.75,
                    "y": 1.25
                },
                "58": {
                    "x": -3.75,
                    "y": -1.25
                },
                "59": {
                    "x": -1.8080783652146115,
                    "y": 0.0
                },
                "60": {
                    "x": 1.8080783652146124,
                    "y": 0.0
                },
                "65": {
                    "x": 3.0616169978683836e-16,
                    "y": -5.0
                },
                "66": {
                    "x": 2.5,
                    "y": -2.5
                },
                "67": {
                    "x": 1.10712869128383e-16,
                    "y": -1.808078365214616
                },
                "68": {
                    "x": 1.0355339059327384,
                    "y": -2.5
                },
                "69": {
                    "x": -2.4999999999999996,
                    "y": -2.5
                },
                "70": {
                    "x": 5.016105304452938e-16,
                    "y": -1.808078365214616
                },
                "71": {
                    "x": -1.0355339059327378,
                    "y": -2.5
                },
                "72": {
                    "x": 1.2785044729599437,
                    "y": -1.2785044729599377
                },
                "73": {
                    "x": 1.2785044729599413,
                    "y": 1.2785044729599377
                },
                "74": {
                    "x": -1.278504472959936,
                    "y": 1.278504472959939
                },
                "75": {
                    "x": -1.2785044729599386,
                    "y": -1.2785044729599395
                },
                "76": {
                    "x": 0.6392522364799724,
                    "y": 1.543291419087275
                },
                "77": {
                    "x": 1.543291419087275,
                    "y": 0.6392522364799706
                },
                "78": {
                    "x": 1.543291419087275,
                    "y": -0.6392522364799689
                },
                "79": {
                    "x": 0.6392522364799689,
                    "y": -1.543291419087275
                },
                "8": {
                    "x": 0.0,
                    "y": 0.0
                },
                "80": {
                    "x": -0.63925223647997,
                    "y": -1.5432914190872749
                },
                "81": {
                    "x": -1.5432914190872746,
                    "y": -0.6392522364799698
                },
                "82": {
                    "x": -1.5432914190872742,
                    "y": 0.6392522364799706
                },
                "83": {
                    "x": -0.6392522364799698,
                    "y": 1.5432914190872742
                },
                "84": {
                    "x": 0.6392522364799671,
                    "y": 1.5432914190872715
                },
                "85": {
                    "x": 1.5432914190872715,
                    "y": 0.6392522364799689
                },
                "86": {
                    "x": 1.543291419087272,
                    "y": -0.6392522364799689
                },
                "87": {
                    "x": 0.639252236479969,
                    "y": -1.5432914190872715
                },
                "88": {
                    "x": -0.639252236479969,
                    "y": -1.5432914190872715
                },
                "89": {
                    "x": -1.5432914190872715,
                    "y": -0.639252236479969
                },
                "9": {
                    "x": 0.0,
                    "y": 0.0
                },
                "90": {
                    "x": -1.5432914190872715,
                    "y": 0.639252236479969
                },
                "91": {
                    "x": -0.6392522364799689,
                    "y": 1.543291419087271
                },
                "92": {
                    "x": -2.5,
                    "y": 1.0355339059327378
                },
                "93": {
                    "x": -2.5,
                    "y": -1.0355339059327378
                },
                "94": {
                    "x": 2.5,
                    "y": -1.0355339059327378
                },
                "95": {
                    "x": 2.5,
                    "y": 1.0355339059327378
                }
            }
        },
        {
            "faces": [
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": 90.0,
                        "t": {
                            "x": 5.000000000000001,
                            "y": 10.0
                        }
                    },
                    "id": "V0",
                    "pts": [
                        6,
                        46,
                        35,
                        21,
                        56,
                        64,
                        63
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -90.0,
                        "t": {
                            "x": 15.0,
                            "y": 10.000000000000002
                        }
                    },
                    "id": "V1",
                    "pts": [
                        7,
                        47,
                        36,
                        22,
                        57,
                        62,
                        61
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -0.0,
                        "t": {
                            "x": 7.5,
                            "y": 5.0
                        }
                    },
                    "id": "V2",
                    "pts": [
                        13,
                        55,
                        56
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -0.0,
                        "t": {
                            "x": 12.5,
                            "y": 5.0
                        }
                    },
                    "id": "V3",
                    "pts": [
                        12,
                        57,
                        58
                    ],
                    "target_side": "verso"
                }
            ],
            "name": "Back",
            "plot_segs": [
                {
                    "p0": 6,
                    "p1": 46
                },
                {
                    "p0": 6,
                    "p1": 63
                },
                {
                    "p0": 7,
                    "p1": 47
                },
                {
                    "p0": 7,
                    "p1": 61
                },
                {
                    "p0": 12,
                    "p1": 57
                },
                {
                    "p0": 12,
                    "p1": 58
                },
                {
                    "p0": 13,
                    "p1": 55
                },
                {
                    "p0": 13,
                    "p1": 56
                },
                {
                    "p0": 21,
                    "p1": 35
                },
                {
                    "p0": 21,
                    "p1": 56
                },
                {
                    "p0": 22,
                    "p1": 36
                },
                {
                    "p0": 22,
                    "p1": 57
                },
                {
                    "p0": 35,
                    "p1": 46
                },
                {
                    "p0": 36,
                    "p1": 47
                },
                {
                    "p0": 55,
                    "p1": 56
                },
                {
                    "p0": 56,
                    "p1": 64
                },
                {
                    "p0": 57,
                    "p1": 58
                },
                {
                    "p0": 57,
                    "p1": 62
                },
                {
                    "p0": 61,
                    "p1": 62
                },
                {
                    "p0": 63,
                    "p1": 64
                }
            ],
            "points": {
                "12": {
                    "x": 2.5,
                    "y": 0.0
                },
                "13": {
                    "x": -2.5,
                    "y": 0.0
                },
                "21": {
                    "x": -2.499999999999999,
                    "y": 2.5
                },
                "22": {
                    "x": 2.5000000000000018,
                    "y": 2.5000000000000004
                },
                "35": {
                    "x": -1.0355339059327395,
                    "y": 2.5
                },
                "36": {
                    "x": 1.0355339059327395,
                    "y": 2.500000000000001
                },
                "46": {
                    "x": 0.0,
                    "y": 1.8080783652146124
                },
                "47": {
                    "x": 1.7763568394002505e-15,
                    "y": 1.8080783652146128
                },
                "55": {
                    "x": -3.75,
                    "y": -1.25
                },
                "56": {
                    "x": -3.75,
                    "y": 1.25
                },
                "57": {
                    "x": 3.75,
                    "y": 1.25
                },
                "58": {
                    "x": 3.75,
                    "y": -1.25
                },
                "6": {
                    "x": 1.7763568394002505e-15,
                    "y": -5.0
                },
                "61": {
                    "x": 3.7500000000000018,
                    "y": -1.25
                },
                "62": {
                    "x": 2.5000000000000018,
                    "y": 8.881784197001252e-16
                },
                "63": {
                    "x": -3.749999999999999,
                    "y": -1.25
                },
                "64": {
                    "x": -2.499999999999999,
                    "y": 0.0
                },
                "7": {
                    "x": 1.7763568394002505e-15,
                    "y": -5.0
                }
            }
        },
        {
            "faces": [
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -0.0,
                        "t": {
                            "x": 10.0,
                            "y": 5.0
                        }
                    },
                    "id": "SL",
                    "pts": [
                        96,
                        97,
                        13
                    ],
                    "target_side": "verso"
                },
                {
                    "Tside_fold": {
                        "a": 1,
                        "r": -0.0,
                        "t": {
                            "x": 10.0,
                            "y": 5.0
                        }
                    },
                    "id": "SR",
                    "pts": [
                        65,
                        12,
                        7,
                        17,
                        16
                    ],
                    "target_side": "verso"
                }
            ],
            "name": "Inside",
            "plot_segs": [
                {
                    "p0": 7,
                    "p1": 12
                },
                {
                    "p0": 7,
                    "p1": 17
                },
                {
                    "p0": 12,
                    "p1": 65
                },
                {
                    "p0": 13,
                    "p1": 96
                },
                {
                    "p0": 13,
                    "p1": 97
                },
                {
                    "p0": 16,
                    "p1": 17
                },
                {
                    "p0": 16,
                    "p1": 65
                },
                {
                    "p0": 96,
                    "p1": 97
                }
            ],
            "points": {
                "12": {
                    "x": 5.0,
                    "y": 0.0
                },
                "13": {
                    "x": -5.0,
                    "y": 0.0
                },
                "16": {
                    "x": 0.0,
                    "y": -5.0
                },
                "17": {
                    "x": 0.0,
                    "y": 5.0
                },
                "65": {
                    "x": 0.0,
                    "y": -5.0
                },
                "7": {
                    "x": 0.0,
                    "y": 5.0
                },
                "96": {
                    "x": 0.0,
                    "y": -5.0
                },
                "97": {
                    "x": 0.0,
                    "y": 5.0
                }
            }
        }
    ]
}